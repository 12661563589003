<template>
  <div class="position-relative pb-1">
    <div v-if="loading" class="loader-overlay">
      <b-spinner style="width: 4rem; height: 4rem" variant="primary" />
    </div>
    <b-card>
      <form
        ref=""
        color="#7367F0"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="submit"
      >
        <validation-observer ref="externalEventForm" tag="form">
          <b-row>
            <b-col md="6">
              <b-form-group label="Title *" label-for="Title">
                <validation-provider
                  #default="{ errors }"
                  name="Title"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.title"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Event Host *" label-for="Event Host">
                <validation-provider
                  #default="{ errors }"
                  name="Event Host"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.eventHost"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Event Host"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="Location *" label-for="Location">
                <validation-provider
                  #default="{ errors }"
                  name="Location"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.location"
                    :state="errors.length > 0 ? false : null"
                    placeholder="Location"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group label="External link: *" label-for="External link:">
                <validation-provider
                  #default="{ errors }"
                  name="External link:"
                  rules="required"
                >
                  <b-form-input
                    v-model="value.externalLink"
                    :state="errors.length > 0 ? false : null"
                    placeholder="External link:"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6">
              <b-form-group
                label-for="date"
                class="text-capitalize"
                label="Date *"
              >
                <validation-provider
                  #default="{ errors }"
                  name="date"
                  rules="required"
                >
                  <date-picker
                    v-model="value.date"
                    format="YYYY-MM-DD"
                    type="date"
                    :disabled-date="disableDateAfter"
                    class="w-100"
                    :class="
                      errors.length > 0 ? 'border-danger-for-time-picker' : ''
                    "
                    value-type="format"
                    placeholder="Date"
                  ></date-picker>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group
                label-for="From"
                class="text-capitalize"
                label="From *"
              >
                <date-picker
                  id="from"
                  v-model="value.from"
                  format="HH:mm"
                  :minute-step="5"
                  type="time"
                  value-type="format"
                  :class="value.from ? '' : ''"
                  :editable="false"
                  :clearable="false"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group label-for="To" class="" label="To">
                <date-picker
                  id="to"
                  v-model="value.to"
                  format="HH:mm"
                  :minute-step="5"
                  type="time"
                  value-type="format"
                  :class="value.to ? '' : ''"
                  :editable="false"
                  :clearable="false"
                ></date-picker>
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Description *" label-for="Description">
                <validation-provider
                  #default="{ errors }"
                  name="Description"
                  rules="required"
                >
                  <quill-editor
                    id="Description"
                    :options="editorOptions"
                    v-model="value.description"
                    :state="errors.length > 0 ? false : null"
                    :class="
                      errors.length > 0 ? 'border-danger-for-text-editor' : ''
                    "
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <div class="col-6 d-flex mb-2">
              <b-form-group label="Event Image" label-for="Event Image">
                <div @click="$refs.myFile.click()" class="profile-img">
                  <input
                    ref="myFile"
                    type="file"
                    class="hidden"
                    @change="onFileChange"
                  />
                  <div v-if="!value._id">
                    <img
                      v-if="media1"
                      class="profile-img"
                      :src="media1 + (isPreview ? '' : '?' + Math.random())"
                      alt="profile image"
                    />
                    <img
                      v-else
                      class="profile-img"
                      :src="require('@/assets/images/pages/upload2.png')"
                      alt=""
                    />
                  </div>
                  <div v-else>
                    <img
                      v-if="!media1"
                      class="profile-img"
                      :src="
                        value.media.path +
                        (isPreview ? '' : '?' + Math.random())
                      "
                      alt="profile image"
                    />
                    <img
                      v-else
                      class="profile-img"
                      :src="media1 + (isPreview ? '' : '?' + Math.random())"
                      alt="profile image"
                    />
                    <label>Change image</label>
                  </div>
                  <small class="text-danger">{{ imageErrorMessage }}</small>
                </div>
              </b-form-group>
            </div>
          </b-row>
        </validation-observer>
        <b-button
          id="show-btn"
          @click="submit"
          class="float-right"
          variant="primary"
          :disabled="loading"
          >{{loading ? 'Saving...' : 'Save'}}</b-button
        >
        <b-button
          id="cancel-btn"
          @click="close"
          class="float-right mr-1"
          variant="primary"
          >Cancel</b-button
        >
      </form>
    </b-card>
  </div>
</template>
  
  <script>
import { FormWizard, TabContent } from "vue-form-wizard";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import DatePicker from "vue2-datepicker";

import "vue-form-wizard/dist/vue-form-wizard.min.css";
// eslint-disable-next-line
import "quill/dist/quill.core.css";
// eslint-disable-next-line
import "quill/dist/quill.snow.css";
// eslint-disable-next-line
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";

import {
  BFormGroup,
  BFormInput,
  BCard,
  BRow,
  BFormInvalidFeedback,
  BCol,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BSpinner,
  BFormDatepicker,
  BButton,
} from "bootstrap-vue";
import { required } from "@validations";
import SharedService from "@/services/SharedService.js";
import "vue-phone-number-input/dist/vue-phone-number-input.css";

export default {
  components: {
    BFormDatepicker,
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    BButton,
    BRow,
    BCol,
    BFormGroup,
    BInputGroup,
    BFormTextarea,
    BFormInput,
    TabContent,
    vSelect,
    BFormInvalidFeedback,
    BSpinner,
    DatePicker,
    BCard,
    BInputGroupAppend,
    BFormCheckbox,
    quillEditor,
  },
  props: ["value", "action"],
  data() {
    return {
      sharedService: new SharedService(),
      loading: false,
      formData: null,
      required,
      languageLoader: true,
      topicLoader: true,
      languagesList: [],
      topicsList: [],
      isPreview: false,
      media: null,
      media1: null,
      imageErrorMessage: undefined,
      editorOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"],
            [{ list: "ordered" }, { list: "bullet" }],
            [{ script: "sub" }, { script: "super" }],
            [{ indent: "-1" }, { indent: "+1" }],
            [{ header: [1, 2, 3, 4, 5, 6, false] }],
            [{ color: [] }, { background: [] }],
          ],
        },
      },
    };
  },
  mounted() {},
  watch: {},
  methods: {
    submit() {
      this.$refs.externalEventForm.validate().then((success) => {
        if (!this.value._id) {
          if (this.media == null) {
            this.imageErrorMessage = "The image is required";
            return;
          }
        }
        if (success) {
          this.loading = true;
          this.value.date = this.getTime(this.value.from, this.value.date);
          const formData = new FormData();
          formData.append("title", this.value.title);
          formData.append("eventHost", this.value.eventHost);
          formData.append("location", this.value.location);
          formData.append("externalLink", this.value.externalLink);
          formData.append("from", this.value.from);
          formData.append("to", this.value.to);
          formData.append("date", this.value.date);
          formData.append("isExternal", true);
          formData.append("type", "external");
          formData.append("description", this.value.description);
          if(this.media != null) formData.append("media", this.media);
          this.$emit("formSubmitted", formData);
        } else {
        }
      });
    },
    getTime(time, date) {
      const nowDate = new Date(date);
      var hours = Number(time.match(/^(\d+)/)[1]);
      var minutes = Number(time.match(/:(\d+)/)[1]);
      var AMPM = time.match(/\s(.*)$/)[1];
      if (AMPM == "pm" && hours < 12) hours = hours + 12;
      if (AMPM == "am" && hours == 12) hours = hours + 12;
      var sHours = hours.toString();
      var sMinutes = minutes.toString();
      if (hours < 10) sHours = "0" + sHours;
      if (minutes < 10) sMinutes = "0" + sMinutes;
      var dateWithHour = nowDate.setHours(sHours, sMinutes);
      return new Date(dateWithHour).toISOString();
    },
    close() {
      this.$router.push("/external-event");
    },
    disableDateAfter(date) {
      const nowDate = new Date();
      nowDate.setHours(0, 0, 0, 0);
      return date < nowDate;
    },
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      this.media = e.target.files[0];
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        this.media1 = e.target.result;
        this.isPreview = true;
      };
      reader.readAsDataURL(file);
    },
  },
  computed: {},
};
</script>
<style>
.profile-img {
  width: 140px;
  height: 140px;
  border-radius: 1.357rem;
  object-fit: cover;
  cursor: pointer;
  border: groove;
}
.ql-editor {
  min-height: 100px;
}
</style>
