<template>
  <div class="">
    <ExternalEventForm ref="ExternalEventForm" v-model="formData" @formSubmitted="storeExternalEvent"/>
  </div>
</template>

<script>
import ExternalEventForm from "@/views/components/external-event/ExternalEventForm";
import ExternalEventService from "@/services/ExternalEventService";

export default {
  name: "CreateExternalEvent",
  components: {
    ExternalEventForm
  },
  data() {
    return {
      externalEventService: new ExternalEventService(),
      formData: {
        // what key i have
      },
    }
  },
  methods: {
    storeExternalEvent(e) {
      this.externalEventService.create(e).then(res => {
        this.$router.push('/external-event')
      }).catch(err => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>
</style>
