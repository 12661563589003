var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"position-relative pb-1"},[(_vm.loading)?_c('div',{staticClass:"loader-overlay"},[_c('b-spinner',{staticStyle:{"width":"4rem","height":"4rem"},attrs:{"variant":"primary"}})],1):_vm._e(),_c('b-card',[_c('form',{ref:"",staticClass:"mb-3",attrs:{"color":"#7367F0","title":null,"subtitle":null,"shape":"square","finish-button-text":"Submit","back-button-text":"Previous"},on:{"on-complete":_vm.submit}},[_c('validation-observer',{ref:"externalEventForm",attrs:{"tag":"form"}},[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Title *","label-for":"Title"}},[_c('validation-provider',{attrs:{"name":"Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Title"},model:{value:(_vm.value.title),callback:function ($$v) {_vm.$set(_vm.value, "title", $$v)},expression:"value.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Event Host *","label-for":"Event Host"}},[_c('validation-provider',{attrs:{"name":"Event Host","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Event Host"},model:{value:(_vm.value.eventHost),callback:function ($$v) {_vm.$set(_vm.value, "eventHost", $$v)},expression:"value.eventHost"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Location *","label-for":"Location"}},[_c('validation-provider',{attrs:{"name":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"Location"},model:{value:(_vm.value.location),callback:function ($$v) {_vm.$set(_vm.value, "location", $$v)},expression:"value.location"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"External link: *","label-for":"External link:"}},[_c('validation-provider',{attrs:{"name":"External link:","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"state":errors.length > 0 ? false : null,"placeholder":"External link:"},model:{value:(_vm.value.externalLink),callback:function ($$v) {_vm.$set(_vm.value, "externalLink", $$v)},expression:"value.externalLink"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{staticClass:"text-capitalize",attrs:{"label-for":"date","label":"Date *"}},[_c('validation-provider',{attrs:{"name":"date","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('date-picker',{staticClass:"w-100",class:errors.length > 0 ? 'border-danger-for-time-picker' : '',attrs:{"format":"YYYY-MM-DD","type":"date","disabled-date":_vm.disableDateAfter,"value-type":"format","placeholder":"Date"},model:{value:(_vm.value.date),callback:function ($$v) {_vm.$set(_vm.value, "date", $$v)},expression:"value.date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{staticClass:"text-capitalize",attrs:{"label-for":"From","label":"From *"}},[_c('date-picker',{class:_vm.value.from ? '' : '',attrs:{"id":"from","format":"HH:mm","minute-step":5,"type":"time","value-type":"format","editable":false,"clearable":false},model:{value:(_vm.value.from),callback:function ($$v) {_vm.$set(_vm.value, "from", $$v)},expression:"value.from"}})],1)],1),_c('b-col',{attrs:{"md":"3"}},[_c('b-form-group',{attrs:{"label-for":"To","label":"To"}},[_c('date-picker',{class:_vm.value.to ? '' : '',attrs:{"id":"to","format":"HH:mm","minute-step":5,"type":"time","value-type":"format","editable":false,"clearable":false},model:{value:(_vm.value.to),callback:function ($$v) {_vm.$set(_vm.value, "to", $$v)},expression:"value.to"}})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":"Description *","label-for":"Description"}},[_c('validation-provider',{attrs:{"name":"Description","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('quill-editor',{class:errors.length > 0 ? 'border-danger-for-text-editor' : '',attrs:{"id":"Description","options":_vm.editorOptions,"state":errors.length > 0 ? false : null},model:{value:(_vm.value.description),callback:function ($$v) {_vm.$set(_vm.value, "description", $$v)},expression:"value.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('div',{staticClass:"col-6 d-flex mb-2"},[_c('b-form-group',{attrs:{"label":"Event Image","label-for":"Event Image"}},[_c('div',{staticClass:"profile-img",on:{"click":function($event){return _vm.$refs.myFile.click()}}},[_c('input',{ref:"myFile",staticClass:"hidden",attrs:{"type":"file"},on:{"change":_vm.onFileChange}}),(!_vm.value._id)?_c('div',[(_vm.media1)?_c('img',{staticClass:"profile-img",attrs:{"src":_vm.media1 + (_vm.isPreview ? '' : '?' + Math.random()),"alt":"profile image"}}):_c('img',{staticClass:"profile-img",attrs:{"src":require('@/assets/images/pages/upload2.png'),"alt":""}})]):_c('div',[(!_vm.media1)?_c('img',{staticClass:"profile-img",attrs:{"src":_vm.value.media.path +
                      (_vm.isPreview ? '' : '?' + Math.random()),"alt":"profile image"}}):_c('img',{staticClass:"profile-img",attrs:{"src":_vm.media1 + (_vm.isPreview ? '' : '?' + Math.random()),"alt":"profile image"}}),_c('label',[_vm._v("Change image")])]),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(_vm.imageErrorMessage))])])])],1)],1)],1),_c('b-button',{staticClass:"float-right",attrs:{"id":"show-btn","variant":"primary","disabled":_vm.loading},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.loading ? 'Saving...' : 'Save'))]),_c('b-button',{staticClass:"float-right mr-1",attrs:{"id":"cancel-btn","variant":"primary"},on:{"click":_vm.close}},[_vm._v("Cancel")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }